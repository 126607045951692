import React, { useState, useEffect, useRef } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { animated, useTrail } from 'react-spring'

// Components
import Layout from 'src/layouts/BaseLayout'
import BreadCrumb from 'src/components/Breadcrumb'
import Icon from 'src/components/UI/MarkdownIcon'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ScrollTo from 'src/components/ScrollTo'
import Tab from 'src/components/Tab'
import { Modal } from 'src/components/Modal'
import OpenAccountPJForm from 'src/components/UI/Forms/OpenAccountPJForm'
import OpenVideo from 'src/components/OpenVideo'
import FAQ from 'src/components/StructuredData/FAQ'

// hooks
import useDomReady from 'src/hooks/window/useDomReady'
import useScroll from '../../../../../hooks/window/useScroll'
import isVisible from '../../../../../utils/isVisible'

// assets
import playVideo from '../../../../../assets/images/shared/play-video-orange.png'
import pageContext from './pageContext.json'
import usePageQuery from './pageQuery'
import BlogArticlesPjAccountJSON from './assets/data/BlogArticlesPjAccount.json'
import TabSectionJSON from './assets/data/TabSection.json'

import ComoSolicitar from './sections/comoSolicitar/_index'

// Style
import {
  Wrapper,
  ContentHeader,
  ScrollLink,
  PerguntasFrequentes,
  SolutionsTable,
  TableCell,
  TableSection,
  ImgSize,
  BlogCarousel,
  TabSection,
  OpenVideoContainer,
  UniverseOfOpportunitiesSection,
  BenefitsSection,
  ForWhoIsSection,
  FaresCard,
} from './style'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

type ITabSection = {
  title: string;
  description: string;
  image: string;
  alt: string;
  hasButton?: boolean;
  hasButtonPD?: boolean;
  hasButtonCC?: boolean;
}

type RequestDigitalPj = {
  question: string;
  title: string;
  description: string;
  image: string;
  alt: string;
}

type BlogArticlesPj = {
  image: string;
  primaryTag: string;
  secundaryTag: string;
  title: string;
  data: string;
  description: string;
  link: string;
  alt: string;
}

const ContaDigitalPJ = () => {
  const data = usePageQuery()
  const scroll = useScroll(300)
  const domReady = useDomReady()

  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ cnpj, setCnpj ] = useState('')

  // Refs
  const headerRef = useRef<HTMLDivElement>(null)
  const benefitsRef = useRef<HTMLDivElement>(null)
  const resourcesRef = useRef<HTMLDivElement>(null)
  const forWhoIsRef = useRef<HTMLDivElement>(null)
  const universeOfOpportunitiesRef = useRef<HTMLDivElement>(null)

  // Animations
  const [ animatedHeader, setAnimatedHeader ] = useTrail(3, () => (fadeFrom))
  const [ animatedBenefits, setAnimatedBenefits ] = useTrail(3, () => (fadeFrom))
  const [ animatedResources, setAnimatedResources ] = useTrail(3, () => (fadeFrom))
  const [ animatedForWhoIs, setAnimatedForWhoIs ] = useTrail(3, () => (fadeFrom))
  const [ animatedUniverseOfOpportunities, setAnimatedUniverseOfOpportunities ] = useTrail(3, () => (fadeFrom))

  // Abrir modal com url
  const clearLocation = (location: string) => {
    const url = new URL(location)
    const cnpj = url.searchParams.get('cnpj')
    return cnpj
  }

  useEffect(() => {
    const location = clearLocation(window.location.href)

    if (location) {
      setCnpj(location)
      setIsModalOpen(true)
    }
  }, [ ])

  useEffect(() => {
    if (isVisible(headerRef.current, -0.3)) {
      setAnimatedHeader((fadeTo))
    }
    if (isVisible(benefitsRef.current, -0.3)) {
      setAnimatedBenefits((fadeTo))
    }
    if (isVisible(resourcesRef.current, -0.3)) {
      setAnimatedResources((fadeTo))
    }
    if (isVisible(forWhoIsRef.current, -0.3)) {
      setAnimatedForWhoIs((fadeTo))
    }
    if (isVisible(universeOfOpportunitiesRef.current, -0.3)) {
      setAnimatedUniverseOfOpportunities((fadeTo))
    }
  }, [ scroll ])

  const openAccountPJFormModal = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <OpenAccountPJForm closeModal={() => setIsModalOpen(false)} cnpj={cnpj} />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout handleHeaderOpenAccount={() => setIsModalOpen(true)} pageContext={pageContext}>
        {openAccountPJFormModal}
        <ContentHeader ref={headerRef} id='traga-seu-contrato' className='bg-white pt-4 pb-5 py-md-0 d-flex position-relative'>
          <div className='container mb-md-5 pb-md-5'>
            <div className='row justify-content-center justify-content-md-between align-items-center'>
              <div className='d-none d-md-block col-12 col-md-6 col-xl-5 offset-xl-1 pl-lg-0 order-md-last mb-2 mb-md-0 text-right'>
                <ImgSize>
                  <Img fluid={data.contaDigitalInterEmpresas.fluid} alt='Um homem e uma mulher, donos de uma padaria, acessando sua conta digital PJ pelo notebook. Na mesma mesa que o notebook está apoiado, está posta uma tábua com pães produzidos na padaria.' />
                </ImgSize>
              </div>
              <div className='col-12 col-md-6 mt-4 mt-md-0 pr-lg-0'>
                <div className='d-none d-lg-block'>
                  <BreadCrumb
                    sectionName='Conta Digital PJcompleta, gratuita e sem burocracias'
                    type='Empresas'
                    text='Conta Digital PJ'
                    link='/empresas/conta-digital/pessoa-juridica/'
                  />
                </div>
                <animated.h1 style={animatedHeader[0]} className='fs-32 lh-37 fs-lg-40 lh-lg-47 fs-xl-48 lh-xl-56 fw-600 text-grayscale--500 mt-lg-4'>
                  <span className='d-lg-block'>Conta Digital PJ</span> completa, gratuita <span className='d-xl-block'>e sem burocracias.</span>
                </animated.h1>
                <animated.p style={animatedHeader[1]} className='fs-18 lh-22 text-grayscale--500 mt-3 pr-lg-5 mr-lg-5'>
                  <span className='d-xl-block'>No Inter, a sua Conta Jurídica é 100% online e sem </span>
                  <span className='d-xl-block'>tarifas com a agilidade e simplicidade que o seu negócio </span>
                  precisam.
                </animated.p>
                <animated.button
                  style={animatedHeader[2]} data-home-pj='hero' onClick={() => setIsModalOpen(true)}
                  className='fs-14 btn text-white btn--lg btn-orange--extra text-none rounded-3 my-2 fw-700'
                >Abrir Conta PJ gratuita
                </animated.button>
                <animated.div style={animatedHeader[2]}>
                  <Link
                    to='/empresas/conta-digital/mei/' data-home-pj='mei'
                    className='btn btn--outline btn--lg btn--orange rounded-2 fs-14 fw-700 text-none mt-3 mt-md-4 mt-lg-3'
                  >Conhecer a Conta MEI
                  </Link>
                </animated.div>
              </div>
            </div>
          </div>
          <ScrollTo to='#pra-quem-e' styles='text-center d-none d-md-block'>
            <ScrollLink className='pb-md-4'>
              <p className='text-orange--base mb-0 fs-18 lh-22 fw-600'>Conheça a Conta PJ</p>
              <Icon color='orange--base' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
            </ScrollLink>
          </ScrollTo>
        </ContentHeader>
        <BenefitsSection ref={benefitsRef} id='vantagens-pra-voce' className='bg-green--extra-light d-flex justify-content-center align-items-center'>
          <div className='container py-5'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-md-6'>
                <animated.h2 style={animatedBenefits[0]} className='fs-24 lh-28 fs-lg-32 lh-lg-38 fs-xl-40 lh-xl-47'>
                  A conta empresarial<br />que oferece vantagens para você e para o seu negócio.
                </animated.h2>
                <animated.p style={animatedBenefits[1]} className='fs-18 lh-22 text-grayscale--500 mt-3 mb-5'>
                  Aproveite o melhor das soluções da sua Conta Digital e conte com a parceria que só o Inter oferece.
                  <span
                    data-home-pj='vantagens-negocio' onClick={() => setIsModalOpen(true)}
                    className='text-orange--base d-none d-lg-inline cursor-pointer fw-600'
                  > Abra a sua Conta PJ gratuita.
                  </span>
                </animated.p>
                <animated.button
                  style={animatedBenefits[2]} data-home-pj='vantagens-negocio'
                  onClick={() => setIsModalOpen(true)}
                  className='btn text-white btn--lg btn-orange--extra rounded-3 d-none d-md-block d-lg-none fw-700'
                >Abrir Conta PJ gratuita
                </animated.button>
              </div>
              <div className='col-12 col-md-6 '>
                <div className='row'>
                  <div className='col-12 col-md-12 col-lg-6'>
                    <animated.div style={animatedBenefits[2]} className='d-flex justify-content-start align-items-center'>
                      <div className='col-lg-2 col-xl-auto mr-4 d-flex circle-icon'>
                        <OrangeIcon size='MD' color='#ffffff' icon='pix-outline' />
                      </div>
                      <p className='fs-16 lh-20 text-grayscale--500 mb-0'>Pix gratuito e ilimitado</p>
                    </animated.div>
                    <animated.div style={animatedBenefits[2]} className='d-flex justify-content-start align-items-center mt-4'>
                      <div className='col-lg-2 col-xl-auto mr-4 d-flex justify-content-center align-items-center circle-icon'>
                        <OrangeIcon size='MD' color='#ffffff' icon='deposit-by-boleto' />
                      </div>
                      <p className='fs-16 lh-20 text-grayscale--500 mb-0'>100 TEDs e 100 boletos por mês</p>
                    </animated.div>
                    <animated.div style={animatedBenefits[2]} className='d-flex justify-content-start align-items-center mt-4'>
                      <div className='col-lg-2 col-xl-auto mr-4 d-flex justify-content-center align-items-center circle-icon'>
                        <OrangeIcon size='MD' color='#ffffff' icon='deposit-by-check' />
                      </div>
                      <p className='fs-16 lh-20 text-grayscale--500 mb-0'>Boletos de cobrança e de depósito</p>
                    </animated.div>
                    <animated.div style={animatedBenefits[2]} className='d-flex justify-content-start align-items-center mt-4'>
                      <div className='col-lg-2 col-xl-auto mr-4 d-flex justify-content-center align-items-center circle-icon'>
                        <OrangeIcon size='MD' color='#ffffff' icon='transfer-money' />
                      </div>
                      <p className='fs-16 lh-20 text-grayscale--500 mb-0'>Transferência de domicílio bancário</p>
                    </animated.div>
                    <animated.div style={animatedBenefits[2]} className='d-flex justify-content-start align-items-center mt-4'>
                      <div className='col-lg-2 col-xl-auto mr-4 d-flex justify-content-center align-items-center circle-icon'>
                        <OrangeIcon size='MD' color='#ffffff' icon='credit' />
                      </div>
                      <p className='fs-16 lh-20 text-grayscale--500 mb-0'>Antecipação de recebíveis</p>
                    </animated.div>
                  </div>
                  <div className='col-12 col-md-12 col-lg-6'>
                    <animated.div style={animatedBenefits[2]} className='d-flex justify-content-start align-items-center mt-4 mt-lg-0'>
                      <div className='col-lg-2 col-xl-auto mr-4 d-flex justify-content-center align-items-center circle-icon'>
                        <OrangeIcon size='MD' color='#ffffff' icon='consortium' />
                      </div>
                      <p className='fs-16 lh-20 text-grayscale--500 mb-0'>APIs para sua gestão</p>
                    </animated.div>
                    <animated.div style={animatedBenefits[2]} className='d-flex justify-content-start align-items-center mt-4'>
                      <div className='col-lg-2 col-xl-auto mr-4 d-flex justify-content-center align-items-center circle-icon'>
                        <OrangeIcon size='MD' color='#ffffff' icon='invoice' />
                      </div>
                      <p className='fs-16 lh-20 text-grayscale--500 mb-0'>Gestão de acessos e aprovações</p>
                    </animated.div>
                    <animated.div style={animatedBenefits[2]} className='d-flex justify-content-start align-items-center mt-4'>
                      <div className='col-lg-2 col-xl-auto mr-4 d-flex justify-content-center align-items-center circle-icon'>
                        <OrangeIcon size='MD' color='#ffffff' icon='security' />
                      </div>
                      <p className='fs-16 lh-20 text-grayscale--500 mb-0'>Liveness e i-safe</p>
                    </animated.div>
                    <animated.div style={animatedBenefits[2]} className='d-flex justify-content-start align-items-center mt-4'>
                      <div className='col-lg-2 col-xl-auto mr-4 d-flex justify-content-center align-items-center circle-icon'>
                        <OrangeIcon size='MD' color='#ffffff' icon='qr-code' />
                      </div>
                      <p className='fs-16 lh-20 text-grayscale--500 mb-0'>Login por QR Code</p>
                    </animated.div>
                    <animated.button
                      style={animatedBenefits[2]} data-home-pj='vantagens-negocio'
                      onClick={() => setIsModalOpen(true)}
                      className='btn text-white btn--lg btn-orange--extra text-none fw-700 rounded-3 d-md-none mt-5'
                    >Abrir Conta PJ gratuita
                    </animated.button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BenefitsSection>
        <ComoSolicitar onClickButton={() => setIsModalOpen(true)} />
        <TabSection ref={resourcesRef}>
          <div className='container py-5 px-0'>
            <Tab
              items={[ 'Maquininha', 'Internet Banking', 'Pix', 'Cartão Mastercard', 'Folha de pagamento', 'Gestão de Cobrança', 'Integrações', 'Investimentos', 'Condomínio' ]}
              styles='fs-14 lh-17 mb-3'
              classDiv='tab-conta-digital-pj'
              color='#161616'
              activeColor='#07605B'
            >
              {
                TabSectionJSON.map((item: ITabSection, index: number) => (
                  <div className='container pt-md-4' key={index}>
                    <div className='row justify-content-md-between align-items-center'>
                      <div className='col-12 col-md-6 col-lg-5 mt-3 mt-md-0'>
                        <animated.h2 style={animatedResources[0]} className='fs-24 lh-28 fs-lg-32 text-grayscale--500'>{item.title}</animated.h2>
                        <animated.p style={animatedResources[1]} className='fs-16 lh-19 text-grayscale--500' dangerouslySetInnerHTML={{ __html: item.description }} />
                        {item.hasButton === true && (
                          <a href='https://inter.co/empresas/maquininha-de-cartao/' rel='noreferrer' target='_blank'>
                            <button
                              className='btn text-orange--extra btn--lg btn-white border border-primary rounded-3 mt-2 d-none fw-700 text-none d-md-block mt-md-4'
                            >Conheça nossas taxas
                            </button>
                          </a>
                        )}
                        {item.hasButtonPD === true && (
                          <a href='https://inter.co/empresas/maquininha-de-cartao/' rel='noreferrer' target='_blank'>
                            <button
                              className='btn text-orange--extra btn--lg btn-white border border-primary rounded-3 mt-2 d-none fw-700 text-none d-md-block mt-md-4'
                            >Visite o Portal do Desenvolvedor
                            </button>
                          </a>
                        )}
                        {item.hasButtonCC === true && (
                          <a href='https://inter.co/empresas/maquininha-de-cartao/' rel='noreferrer' target='_blank'>
                            <button
                              className='btn text-orange--extra btn--lg btn-white border border-primary rounded-3 mt-2 d-none fw-700 text-none d-md-block mt-md-4'
                            >Conhecer Conta Condomínio
                            </button>
                          </a>
                        )}
                        <animated.button
                          data-home-pj='cta-beneficios' style={animatedResources[2]} onClick={() => setIsModalOpen(true)}
                          className='btn text-white btn--lg btn-orange--extra rounded-3 mt-2 d-none fw-700 text-none d-md-block mt-md-4'
                        >Abrir Conta PJ gratuita
                        </animated.button>
                      </div>
                      <div className='col-12 col-md-6 offset-lg-1 col-xl-5 offset-xl-2 mt-3'>
                        <Img fluid={data[item.image].fluid} alt={item.alt} />
                      </div>
                      <animated.button
                        style={animatedResources[2]} onClick={() => setIsModalOpen(true)}
                        className='btn text-white btn--lg btn-orange--extra text-none rounded-3 mt-5 d-md-none fw-700'
                      >Abrir Conta PJ gratuita
                      </animated.button>
                    </div>
                  </div>
                ))
              }
            </Tab>
          </div>
        </TabSection>
        <ForWhoIsSection id='pra-quem-e' ref={forWhoIsRef} className='py-md-5'>
          <div className='container'>
            <div className='row align-items-center'>
              <animated.div style={animatedForWhoIs[1]} className='col-12 col-md-6 col-xl-5 d-none d-md-block'>
                <Img
                  fluid={data.praQuemEaContaDigital.fluid}
                  alt='Imagem com várias telas do app do Inter'
                />
              </animated.div>
              <div className='col-12 col-md-6 col-lg-5 offset-xl-2'>
                <animated.h2 style={animatedForWhoIs[0]} className='fs-24 lh-28 fs-lg-32 lh-lg-38 fs-xl-40 lh-xl-46 text-grayscale--500'>Para quem é a Conta Digital PJ?</animated.h2>
                <animated.p style={animatedForWhoIs[2]} className='fs-18 lh-22 text-grayscale--500'>
                  Para você, empreendedor que precisa de gestão simplificada do seu negócio. Temos soluções para sua empresa constituída como EI, EIRELI, LTDA, SA ou Condomínio.
                </animated.p>
                <animated.p style={animatedForWhoIs[2]} className='fs-18 lh-22 text-grayscale--500'>
                  Se sua empresa está constituída como MEI, você pode aproveitar as vantagens da nossa <Link to='/empresas/conta-digital/mei/' data-home-pj='para-quem-e-a-conta-mei' className='fw-600 text-orange--extra'>Conta MEI.</Link>
                </animated.p>
                <animated.button
                  style={animatedForWhoIs[2]} data-home-pj='para-quem-e-a-conta' onClick={() => setIsModalOpen(true)}
                  className='btn text-white btn--lg btn-orange--extra text-none rounded-3 my-2 mt-md-4 fw-700'
                >Abrir Conta PJ gratuita
                </animated.button>
              </div>
            </div>
          </div>
        </ForWhoIsSection>
        <TableSection>
          <div className='container py-5'>
            <div className='row justify-content-center'>
              <div className='col-12 col-xl-9'>
                <h2 className='fs-24 lh-28 fs-md-24 fs-lg-32 lh-lg-38 fs-xl-40 lh-xl-46 text-md-center text-green--500'>
                  <span className='d-xl-block'>Benefícios que só a Conta Empresarial</span>
                  do Inter oferece pro seu negócio
                </h2>
                <p className='fs-18 lh-22 text-md-center text-grayscale--500'>
                  Sua Conta Digital CNPJ do Inter tem um pacote de soluções para o dia a dia da sua empresa.
                </p>
              </div>
              <div className='col-12 col-xl-8'>
                <SolutionsTable className='mt-5'>
                  <div className='tabShadow rounded-5' />
                  <TableCell className='d-flex justify-content-between'>
                    <div className='d-flex align-items-center'><h2 className='fs-16 lh-20 mb-0 ml-3 fw-600 text-grayscale--500 py-3'>Serviços</h2></div>
                    <div />
                    <div className='d-flex justify-content-center align-items-center border-green-top py-3'>
                      <p className='fs-16 lh-120 mb-0 text-green-table fw-600'>Taxa</p>
                    </div>
                  </TableCell>
                  <TableCell className='d-flex justify-content-between align-items-center rounded-3 lineColor'>
                    <div><p className='fs-14 lh-17 mb-0 ml-3 ml-md-4 text-grayscale--500'>TEDs e Transferências</p></div>
                    <div className='d-flex justify-content-center'><p className='fs-14 lh-17 mb-0 text-grayscale--500'>100/mês</p></div>
                    <div className='d-flex justify-content-center border-green'>
                      <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                    </div>
                  </TableCell>
                  <TableCell className='d-flex justify-content-between align-items-center'>
                    <div><p className='fs-14 lh-17 mb-0 ml-3 ml-md-4 text-grayscale--500'>Emissão de boletos</p></div>
                    <div className='d-flex justify-content-center'><p className='fs-14 lh-17 mb-0 text-grayscale--500'>100/mês</p></div>
                    <div className='d-flex justify-content-center border-green'>
                      <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                    </div>
                  </TableCell>
                  <TableCell className='d-flex justify-content-between align-items-center rounded-3 lineColor'>
                    <div><p className='fs-14 lh-17 mb-0 ml-3 ml-md-4 text-grayscale--500'>Folhas de pagamento</p></div>
                    <div className='d-flex justify-content-center'>
                      <p className='fs-14 lh-17 mb-0 text-grayscale--500'>Ilimitadas</p>
                    </div>
                    <div className='d-flex justify-content-center border-green'>
                      <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                    </div>
                  </TableCell>
                  <TableCell className='d-flex justify-content-between align-items-center'>
                    <div><p className='fs-14 lh-17 mb-0 ml-3 ml-md-4 text-grayscale--500'>Cartão Mastercard</p></div>
                    <div className='d-flex justify-content-center'>
                      <p className='fs-14 lh-17 mb-0 text-grayscale--500 text-center'>Débito e Crédito<br />(sob análise)</p>
                    </div>
                    <div className='d-flex justify-content-center border-green'>
                      <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                    </div>
                  </TableCell>
                  <TableCell className='d-flex justify-content-between align-items-center rounded-3 lineColor'>
                    <div><p className='fs-14 lh-17 mb-0 ml-3 ml-md-4 text-grayscale--500'>Saques</p></div>
                    <div className='d-flex justify-content-center'>
                      <p className='fs-14 lh-17 mb-0 text-grayscale--500'>Livres</p>
                    </div>
                    <div className='d-flex justify-content-center border-green'>
                      <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                    </div>
                  </TableCell>
                  <TableCell className='d-flex justify-content-between align-items-center'>
                    <div><p className='fs-14 lh-17 mb-0 ml-3 ml-md-4 text-grayscale--500'>Taxa de manutenção</p></div>
                    <div className='d-flex justify-content-center'>
                      <p className='fs-14 lh-17 mb-0 text-grayscale--500'>Anual/mensal</p>
                    </div>
                    <div className='d-flex justify-content-center border-green'>
                      <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                    </div>
                  </TableCell>
                  <TableCell className='d-flex justify-content-between align-items-center rounded-3 lineColor'>
                    <div><p className='fs-14 lh-17 mb-0 ml-3 ml-md-4 text-grayscale--500'>Pix</p></div>
                    <div className='d-flex justify-content-center'>
                      <p className='fs-14 lh-17 mb-0 text-grayscale--500'>Ilimitado</p>
                    </div>
                    <div className='d-flex justify-content-center border-green'>
                      <p className='fs-16 lh-120 mb-0 text-grayscale--500 fw-600'>Zero</p>
                    </div>
                  </TableCell>
                  <TableCell className='d-flex justify-content-between align-items-center'>
                    <div><p className='fs-14 lh-17 mb-0 ml-3 ml-md-4 fw-600 text-green-table'>Custo total dos serviços</p></div>
                    <div />
                    <div className='d-flex justify-content-center border-green-bottom fw-600'>
                      <p className='fs-16 lh-120 mb-0 text-green fw-600 text-green-table'>ZERO</p>
                    </div>
                  </TableCell>
                </SolutionsTable>
              </div>
            </div>
          </div>
        </TableSection>
        <UniverseOfOpportunitiesSection ref={universeOfOpportunitiesRef}>
          <div className='container py-5'>
            <div className='row align-items-center'>
              <div className='col-12 col-md-6 col-xl-5 mb-md-5 mt-5'>
                <animated.h2 style={animatedUniverseOfOpportunities[0]} className='fs-24 lh-28 fs-lg-32 lh-lg-38 fs-xl-40 lh-xl-47 text-white mt-md-3'>
                  <span className='d-xl-block'>Mais que uma conta</span> <span className='d-xl-block'>digital, um universo</span> de oportunidades.
                </animated.h2>
                <animated.p style={animatedUniverseOfOpportunities[1]} className='fs-18 lh-24 text-white mt-md-3'>
                  Conheça o Inter Empresas.
                </animated.p>
              </div>
              <div className='col-12 col-md-6 col-lg-4 offset-lg-1 col-xl-3 offset-xl-4'>
                <OpenVideoContainer>
                  <OpenVideo link='https://www.youtube.com/embed/LzDcDOAOldc'>
                    <div className='d-flex flex-column align-items-center'>
                      <img src={playVideo} alt='Assistir Vídeo' width='85' height='129' className='mb-1' data-home-pj='depoimento' />
                    </div>
                  </OpenVideo>
                </OpenVideoContainer>
              </div>
            </div>
          </div>
        </UniverseOfOpportunitiesSection>
        <section>
          <div className='container py-5'>
            <div className='row align-items-center'>
              <div className='col-12 col-lg-5'>
                <h3 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-md-40 lh-xl-50 text-grayscale--500 text-center text-lg-left'>
                  <span className='d-lg-block'>Por que não</span> cobramos tarifas?
                </h3>
                <p className='fs-18 lh-22 text-grayscale--500 text-center text-lg-left'>
                  Nascemos para fazer diferente. Participamos da vida de milhões de pessoas, reunindo em uma mesma plataforma soluções que fazem a diferença no seu dia a dia.
                </p>
                <p className='fs-18 lh-22 text-grayscale--500 text-center text-lg-left'>
                  E por isso, criamos a primeira conta 100% digital e gratuita do Brasil. Como isso é possível? A gente explica.
                </p>
              </div>
              <div className='col-12 col-lg-6 offset-lg-1 mt-md-2'>
                <div className='row'>
                  <FaresCard className='col-md-6 col-lg-12 px-2'>
                    <div className='d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                      <div className='px-3 pl-md-2'>
                        <OrangeIcon size='MD' color='#FF7A00' icon='smartphones' />
                      </div>
                      <div className='pt-md-0'>
                        <h4 className='fs-20 text-grayscale--500 mb-2'>Somos digitais</h4>
                        <p className='fs-16 lh-22 text-grayscale--500 mb-md-0'>Como você resolve tudo pela internet, não precisamos de agências. Isso aumenta a nossa eficiência e gera economia.</p>
                      </div>
                    </div>
                  </FaresCard>
                  <FaresCard className='col-md-6 col-lg-12 px-2'>
                    <div className='d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                      <div className='px-3 pl-md-2'>
                        <OrangeIcon size='MD' color='#FF7A00' icon='services' />
                      </div>
                      <div className='pt-md-0'>
                        <h4 className='fs-20 text-grayscale--500 mb-2'>Temos processos automatizados</h4>
                        <p className='fs-16 lh-22 text-grayscale--500 mb-md-0'>Também economizamos com o custo operacional. Criamos processos inteligentes, que reduzem esforço e burocracia.</p>
                      </div>
                    </div>
                  </FaresCard>
                  <FaresCard className='col-md-6 col-lg-12 px-2'>
                    <div className='d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                      <div className='px-3 pl-md-2'>
                        <OrangeIcon size='MD' color='#FF7A00' icon='light-on' />
                      </div>
                      <div className='pt-md-0'>
                        <h4 className='fs-20 text-grayscale--500 mb-2'>Somos sustentáveis</h4>
                        <p className='fs-16 lh-22 text-grayscale--500 mb-md-0'>Como não temos agência e somos digitais, economizamos papel, energia e outros recursos.</p>
                      </div>
                    </div>
                  </FaresCard>
                  <FaresCard className='col-md-6 col-lg-12 px-2'>
                    <div className='d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                      <div className='px-3 pl-md-2'>
                        <OrangeIcon size='MD' color='#FF7A00' icon='dollar' />
                      </div>
                      <div className='pt-md-0'>
                        <h4 className='fs-20 text-grayscale--500 mb-2'>Ganhamos em escala</h4>
                        <p className='fs-16 lh-22 text-grayscale--500 mb-md-0'>Você utiliza sua Conta Digital e outros serviços, fica satisfeito com a gente e nos indica para os seus amigos.</p>
                      </div>
                    </div>
                  </FaresCard>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id='dicas-para-investir-melhor' className='bg-white d-flex pb-md-5 pb-xl-0'>
          <div className='container pb-5 pt-md-5'>
            <div className='row'>
              <div className='col-12'>
                <h3 className='fs-24 fs-md-24 fs-lg-32 fs-xl-40 lh-25 lh-lg-40 lh-xl-50 text-md-center fw-600 text-grayscale--500'>
                  Acompanhe as novidades da Conta Digital PJ
                </h3>
                <p className='fs-18 lh-22 mb-0 mb-xl-4 text-md-center text-grayscale--500'>
                  <span className='d-xl-block'>Entenda mais sobre educação financeira, investimentos, e o que mais sua empresa</span> precisar no <span className='fw-600 text-orange--extra'><a href='https://blog.inter.co/' target='blank' data-home-pj='blog'>nosso blog.</a></span>
                </p>
              </div>
              <div className='col-12'>
                <DefaultCarousel
                  sm={{ items: 1 }}
                  md={{ items: 2 }}
                  lg={{ items: 3 }}
                  xl={{ items: 3 }}
                >
                  { BlogArticlesPjAccountJSON.map((item: BlogArticlesPj, index: number) => (
                    <BlogCarousel key={index} className='col-12 p-card mt-md-3'>
                      <article className='col-12 px-0'>
                        <Img fluid={data[item.image].fluid} alt={item.alt} />
                        <div className='col-12 pt-3 rounded-5 px-4 mt-n5 bg-white'>
                          <p className='fs-12 lh-15 fw-600 text-white bg-orange--extra rounded-1 mb-1 px-2 mr-2 primary-tag'>{item.primaryTag}</p>
                          <p className='fs-12 lh-15 fw-600 text-orange--extra bg-gray-400 rounded-1 mb-1 px-2 secundary-tag'>{item.secundaryTag}</p>
                          <p className='fs-20 lh-25 fw-600 mb-2 mt-md-2 mt-xl-2 title-post text-grayscale--500'>{item.title}</p>
                          <span className='fs-12 lh-15 fw-400 d-block text-grayscale--300'>{item.data}</span>
                          <p className='fs-14 lh-19 lh-md-19 mb-2 fw-400 d-none d-md-block mt-3 text-grayscale--500'>{item.description}</p>
                          <a
                            data-blog={item.title}
                            href={item.link}
                            title={`Leia mais em ${item.link}`}
                            className='fs-14 lh-17 fw-400 text-orange--extra text-right d-none d-md-block mt-xl-3'
                          >
                            Leia mais
                            <Icon width='22.5' height='24.34' color='orange--base ml-2' icon='navigation/arrow-right' directory='v2' />
                          </a>
                        </div>
                      </article>
                    </BlogCarousel>
                    ))
                    }
                </DefaultCarousel>
              </div>
            </div>
          </div>
        </section>
        <PerguntasFrequentes id='perguntas-frequentes' className='bg-gray py-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-12 mb-4'>
                <h3 className='fs-24 lh-28 fs-md-40 lh-md-46 text-grayscale--500 mb-2 mb-lg-1'>Ficou com alguma dúvida?</h3>
                <p className='fs-18 lh-22 fw-400 text-grayscale--500'>
                  Veja se podemos te ajudar com estas perguntas frequentes.
                </p>
              </div>
              <FAQ id='conta-pj' styles='summary-content px-0' data={pageContext.structuredData.faq} />
              <div className='col-12 col-md-8 mx-auto text-md-center pt-lg-3 pb-lg-4 d-md-none d-lg-block mt-4'>
                <p className='lh-22 text-grayscale--500 mb-0'>
                  Se quiser saber mais, acesse nossa
                  <a
                    className='text-orange--base' href='https://ajuda.bancointer.com.br' target='_blank'
                    rel='noreferrer'
                  > <u>Central de Ajuda</u>
                  </a> para outras perguntas.
                </p>
              </div>
            </div>
          </div>
        </PerguntasFrequentes>
      </Layout>
    </Wrapper>
  )
}

export default ContaDigitalPJ
