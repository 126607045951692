import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import { CustomSection, NumberDiv } from './style'
import { Link } from 'gatsby'

interface IListItem {
  title: string;
  description: string;
}

const listData = [
  {
    title: 'Faça seu cadastro',
    description: 'Cadastro simplificado onde são necessários apenas os dados de sua empresa, representante legal e o seu contrato social',
  },
  {
    title: 'Confira seu e-mail',
    description: 'Você receberá um token de acesso no seu e-mail e no seu telefone para criar a sua senha e prosseguir com o cadastro.',
  },
  {
    title: 'Envie os documentos da sua empresa',
    description: 'Você enviará a documentação necessária da sua empresa e faremos a análise da abertura de conta. ',
  },
  {
    title: 'Acesse sua conta',
    description: 'Com sua Conta Digital PJ aberta, você receberá um e-mail com os dados da conta e seu Código de Operador.',
  },
]
interface IProps {
  utmLink: string;
}

const ComoSolicitar = ({ utmLink }: IProps) => {
  const data = usePageQuery()

  return (
    <CustomSection className='bg-green--500'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-md-6 col-lg-6 col-xl-5'>
            <Img fluid={data.comoSolicitarContaPj.fluid} alt='Imagem do App do Inter' />
          </div>
          <div className='col-12 col-lg-6'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--100 py-4 py-lg-0 pb-lg-4'>Como abrir minha Conta Jurídica Digital?</h2>
            {listData.map((item: IListItem, index: number) => (
              <div key={index} className='d-flex pb-3'>
                <div>
                  <NumberDiv>
                    {index + 1}
                  </NumberDiv>
                </div>
                <div className='ml-4'>
                  <h4 className='fs-20 lh-25 fw-600 text-white  mb-0 pb-2'>{item.title}</h4>
                  <p className='fs-16 lh-19 text-grayscale--100' dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              </div>
            ))}
            <Link
              to={utmLink}
              className='btn text-white btn--lg btn-orange--extra rounded-3 mt-2 fw-700 mt-md-4 w-100'
            >
              Abrir Conta PJ gratuita
            </Link>
          </div>
        </div>
      </div>
    </CustomSection>
  )
}

export default ComoSolicitar
