import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      contaDigitalInterEmpresas: imageSharp(fluid: { originalName: { regex:"/img-header/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      praQuemEaContaDigital: imageSharp(fluid: { originalName: { regex:"/pra-quem-e-a-conta-digital/" }}) {
        fluid(maxWidth: 560, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      facaSeuCadastro: imageSharp(fluid: { originalName: { regex:"/faca-seu-cadastro/" }}) {
        fluid(maxWidth: 456, quality: 100) {
        ...GatsbyImageSharpFluid
        }
      }
      confiraSeuEmail: imageSharp(fluid: { originalName: { regex:"/confira-seu-email/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      envieOsDocumentos: imageSharp(fluid: { originalName: { regex:"/envie-documentos/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      acesseSuaConta: imageSharp(fluid: { originalName: { regex:"/acesse-sua-conta-pj/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      postContaDigitalParaEmpresas: imageSharp(fluid: { originalName: { regex:"/post-conta-digital-para-empresas/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      postPixParaEmpresas: imageSharp(fluid: { originalName: { regex:"/post-pix-para-empresas/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      postDuvidasFrequentesMei: imageSharp(fluid: { originalName: { regex:"/post-duvidas-frequentes-mei/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      facilidade: imageSharp(fluid: { originalName: { regex:"/facilidade-maquininha-granito/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      internetBanking: imageSharp(fluid: { originalName: { regex:"/internet-banking/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      pixGarantido: imageSharp(fluid: { originalName: { regex:"/pix-garantido/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      cartaoInterMastercardEmpresa: imageSharp(fluid: { originalName: { regex:"/cartao-inter-mastercard-empresa/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      menosBurocracia: imageSharp(fluid: { originalName: { regex:"/menos-burocracia/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      praticidadeHoraReceber: imageSharp(fluid: { originalName: { regex:"/praticidade-hora-receber/" }}) {
        fluid(maxWidth: 464, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      maquininhasGranitoApis: imageSharp(fluid: { originalName: { regex:"/maquininhas-Granito-Apis/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      comoSolicitarContaPj: imageSharp(fluid: { originalName: { regex:"/como-solicitar-conta-pj/" } }) {
        fluid(maxWidth: 564, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      investimentosCaixaRender: imageSharp(fluid: { originalName: { regex:"/investimentos-caixa-render/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      contaCondominio: imageSharp(fluid: { originalName: { regex:"/conta-condominio/" } }) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
